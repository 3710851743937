import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
function Announcement() {
    return ( 
        <>
        
            <div className="flex items-center justify-center m-8 p-8 space-x-4">
                <img className="h-8 w-auto" src={icon1} alt="icon1" />
                <p className="text-3xl font-bold text-[#F17951]">Announcement</p>
                <img className="h-8 w-auto" src={icon2} alt="icon2" />
            </div>
            <div className="mx-12 bg-[#ffd2b3] shadow-lg shadow-black p-4 mb-4">
                <h1 className="text-3xl font-bold my-4">Celebrating Mahatma Gandhi’s Values at Shree Vrihadnarayan Gaishala on 2nd October</h1>
                <p>On this special day, 2nd October, the birth anniversary of Mahatma Gandhi, Shree Vrihadnarayan Gaishala embraced his values of kindness, compassion, and service to all living beings. Our Gaushala, which cares for and nurtures our sacred cows, organized a series of events to honor Gandhi’s principles of non-violence and selfless service.</p>
                
                <h1 className="text-xl font-bold text-center my-8">Activities Conducted</h1>
                <p className="mx-4 py-2"> <span className="font-bold ">Morning Prayer & Bhajan:</span>
                     We began the day with soulful bhajans, remembering the teachings of Mahatma Gandhi and the spiritual connection he had with animals and nature.</p>
                
                <p className="mx-4 py-2"><span className="font-bold">Cow Feeding Ceremony: </span>Devotees and volunteers participated in feeding our beloved cows with fresh grass, grains, and jaggery, symbolizing care and respect for all life.</p>
                
                <p className="mx-4 py-2"><span className="font-bold">Community Service:</span> A cleanliness drive was organized around the Gaushala and surrounding areas, inspired by Gandhi’s belief in Swachh Bharat (Clean India). Volunteers came together to keep the environment clean and green.</p>
                
                <p className="mx-4 py-2"><span className="font-bold">Gau-Seva Awareness Campaign:</span> A short talk on the importance of Gau-Seva (service to cows) in today’s world was held, encouraging visitors and volunteers to participate in ongoing Gaushala activities.</p>
                
                <p className="mx-4 py-2"><span className="font-bold">Tree Plantation Drive:</span> In alignment with Gandhi’s vision of sustainability, we conducted a tree plantation event to contribute to the welfare of nature and promote eco-friendly practices.</p>
                
                <p className="mx-4 py-2"><span className="font-bold">Charity & Donation Drive: </span>We welcomed donations of fodder, medical supplies, and other essentials to support the Gaushala’s efforts in caring for cows.</p>

                
            </div>

            <div className="mx-12 my-24 bg-[#ffd2b3] shadow-lg shadow-black p-4 mb-4">
                <h1 className="text-3xl font-bold my-4">
                    Free Milk Distribution at Shree Vrihadnarayan Gaushala on 25th September
                </h1>
                <p>
                    On 25th September, Shree Vrihadnarayan Gaushala organized a special Free Milk Distribution event for the local community. As part of our ongoing commitment to promoting health and nourishment, we distributed fresh, pure cow’s milk to families in need, bringing warmth and sustenance to all.
                </p>

                <h1 className="text-xl font-bold text-center my-8">Purpose of the Event</h1>
                <p className="mx-4 py-2">
                    <span className="font-bold">Serving the Community:</span> In alignment with our mission to serve both cows and people, this event allowed us to share the natural goodness of cow’s milk, fostering a deeper connection between the community and our Gaushala.
                </p>

                <p className="mx-4 py-2">
                    <span className="font-bold">Supporting Health & Nutrition:</span> Fresh cow's milk, rich in nutrients, was provided to promote the well-being of families, especially those who may not have regular access to such a valuable resource.
                </p>

                <p className="mx-4 py-2">
                    <span className="font-bold">Community Engagement:</span> The event saw enthusiastic participation, with members of the local community coming together to support the Gaushala’s efforts, strengthening the bonds of care and compassion.
                </p>
            </div>

            <div className="mx-12 my-24 bg-[#ffd2b3] shadow-lg shadow-black p-4 mb-4">
                <h1 className="text-3xl font-bold my-4">
                    Cleaning Drive at Shree Vrihadnarayan Gaushala Every Sunday
                </h1>
                <p>
                    On each sunday, Shree Vrihadnarayan Gaushala organized a community-driven Cleaning Drive to ensure the cleanliness and hygiene of the Gaushala premises. Volunteers and staff came together to clean the area and maintain a healthy environment for our beloved cows.
                </p>

                <h1 className="text-xl font-bold text-center my-8">Objectives of the Cleaning Drive</h1>

                <p className="mx-4 py-2">
                    <span className="font-bold">Promoting Cleanliness:</span> Our Gaushala believes in maintaining a clean and hygienic environment for both cows and visitors. This cleaning initiative was aimed at ensuring the Gaushala remains a healthy space for all.
                </p>

                <p className="mx-4 py-2">
                    <span className="font-bold">Volunteer Engagement:</span> A large number of volunteers from the community participated in the cleaning activities, working together in a spirit of service and dedication.
                </p>

                <p className="mx-4 py-2">
                    <span className="font-bold">Sustainability Focus:</span> The drive also focused on proper waste management, segregating recyclable materials and ensuring eco-friendly practices in waste disposal.
                </p>

                <h1 className="text-xl font-bold text-center my-8">Impact</h1>

                <p className="mx-4 py-2">
                    <span className="font-bold">A Cleaner Environment:</span> By the end of the drive, the Gaushala was visibly cleaner, creating a more welcoming and hygienic atmosphere for both the cows and the visitors.
                </p>

                <p className="mx-4 py-2">
                    <span className="font-bold">Community Bonding:</span> The event fostered a sense of unity and collective responsibility, as everyone worked together for a common cause—ensuring the well-being of our Gaushala and its residents.
                </p>
            </div>


        </>
     );
}

export default Announcement;