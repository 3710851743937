import React, { useContext } from "react";
import bull from "../assests/MonthCareImage/bull.jpeg";
import calf from "../assests/MonthCareImage/calf.jpg";
import careCow from "../assests/MonthCareImage/careCow.jpeg";
import milkGivingCow from "../assests/MonthCareImage/milkGivingCow.jpg";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { CartContext } from "../components/Context";
import SubHeader from "../components/SubHeader";

function MonthlyCare() {
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  const items = [
    {
      id: 12,
      type: "donate_product",
      title: "Monthly Care Of A Cow",
      image: careCow,
      price: 2100,
      unit: "per cow per month",
      text: "We provide comprehensive care for your adopted gauvansh, ensuring their shelter, food, medicines, and daily needs are met. This initiative helps save the precious lives of our mother cows, alleviating concerns about their micromanagement. Your support makes a meaningful difference in their well-being.",
    },
    {
      id: 13,
      type: "donate_product",
      title: "Monthly Care Of A Milk Giving Cows",
      image: milkGivingCow,
      price: 3500,
      unit: "per cow per month",
      text: "We provide full care for your adopted milk-giving cows, addressing all their daily needs. Their milk is freely distributed to saints and those in need. This initiative saves the precious lives of our mother cows while ensuring their milk nourishes those who require it. Your support truly matters.",
    },
    {
      id: 14,
      type: "donate_product",
      title: "Monthly Care Of A Calf",
      image: calf,
      price: 1100,
      unit: "per calf per month",
      text: "We provide comprehensive care for your adopted calf under two years of age, ensuring their shelter, food, medicines, and daily needs are met. This initiative saves the precious life of the calf, relieving you of any concerns about micromanagement. Your support makes a significant difference in their well-being.",
    },
    {
      id: 15,
      type: "donate_product",
      title: "Monthly Care Of A Bull",
      image: bull,
      price: 3000,
      unit: "per bull per month",
      text: "We provide full care for your adopted bull, ensuring their shelter, food, medicines, and daily needs are met. This initiative saves the precious life of the bull, allowing you to relax without concerns about micromanagement. Your support is vital in enhancing their well-being.",
    },
  ];

  return (
    <>
    <SubHeader/>
    <div className="container mx-auto p-6 bg-[#ffd2b3] rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center text-[#F17951] mb-6">
        Monthly care of a cow
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map((item) => (
          <div
            key={item.id}
            className="border rounded-lg p-4 text-center shadow-md shadow-black flex flex-col justify-between"
          >
            <div>
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-60 object-cover mb-2 rounded"
              />
            </div>

            <div>
              <h3 className="font-semibold text-lg">{item.title}</h3>
              <p >{item.text}</p>
            </div>

            <div className="flex justify-between items-center">

              <div className="flex justify-between my-4">
                <div>
                  <h1 className="text-lg font-bold">{item.price}/- </h1>
                  <h3>{item.unit}</h3>
                </div>
              </div>

              <div className="bg-[#F17951] flex justify-between items-center rounded-lg">
                <button
                  onClick={() => removeFromCart(item.id)}
                  className="px-4 py-3"
                >
                  <FiMinus className="border-2 border-black" />
                </button>
                <p>
                  {cartItems.find((cartItem) => cartItem.id === item.id)
                    ?.quantity || 0}
                </p>
                <button onClick={() => addToCart(item)} className="px-4">
                  <IoMdAdd className="border-2 border-black" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
   
  );
}

export default MonthlyCare;
