import React from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import SubHeader from "../components/SubHeader";

const PrivacyPage = () => {
  return (
    <div className="bg-gray-100">
      <SubHeader />
      <div className="flex items-center justify-center m-8 p-4 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951]">Privacy Policy</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="shadow-lg shadow-black m-8 p-8 rounded-lg bg-[#ffd2b3]">
        <h2 className="text-xl font-semibold mb-4">Welcome</h2>
        <p>
          Welcome to the official website of Shri Narayan Vrihad Gaushala. We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Information We Collect</h3>
        <ul className="list-disc list-inside mb-4">
          <li>Personal information (name, email, phone number).</li>
          <li>Payment details for donations (processed securely).</li>
          <li>Non-personal data (IP address, browser type, pages visited).</li>
          <li>Cookies to track your activity on our site.</li>
        </ul>

        <h3 className="mt-6 text-lg font-semibold">How We Use Your Information</h3>
        <p>
          We use the information we collect to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide and manage our services.</li>
          <li>Process donations and respond to inquiries.</li>
          <li>Improve our site and user experience.</li>
          <li>Send updates and news (opt-out available).</li>
          <li>Comply with legal obligations.</li>
        </ul>

        <h3 className="mt-6 text-lg font-semibold">Data Protection</h3>
        <p>
          We take appropriate measures to protect your personal information. However, no method of transmission or storage is entirely secure. We do not sell or trade your personal information.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Your Rights</h3>
        <p>
          You have the right to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Request access to your personal data.</li>
          <li>Opt out of promotional communications.</li>
          <li>Manage or disable cookies.</li>
        </ul>

        <h3 className="mt-6 text-lg font-semibold">Updates to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Changes will be posted here with an updated effective date. Please review this policy periodically.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Contact Us</h3>
        <p>
          If you have questions or concerns regarding this Privacy Policy, please contact us at Shri Narayan Vrihad Gaushala via email or phone.
          <a
            href="https://wa.me/918595952508"
            className="flex items-center gap-2"
          >
            <FaWhatsapp color="green" size={24} /> +91 8595952508
          </a>
          <a
            href="mailto:vgf@vallabhiindia.com"
            className="flex items-center gap-2"
          >
            <CiMail color="red" size={24} /> <span>vgf@vallabhiindia.com</span>{" "}
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
