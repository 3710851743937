import React from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import image from "../assests/sir.JPG";
import eng from "../assests/servicesImage/eng.png";
import construction from "../assests/servicesImage/constra.png";
import cow from "../assests/servicesImage/green.png";
import diagonostics from "../assests/servicesImage/diagno.png";
import farmer from "../assests/servicesImage/agro.png";
import hospitals from "../assests/servicesImage/kleem.png";
import impo_expo from "../assests/servicesImage/impex.png";
import software from "../assests/servicesImage/it.png";
import drugs from "../assests/servicesImage/phygital.png";
import financial from "../assests/servicesImage/drvya.png";
import narad from "../assests/servicesImage/narad.png";
import bani from "../assests/servicesImage/bani.png";
import SubHeader from "../components/SubHeader";

const AboutRead = () => {
  const vallabhiServices = [
    {
      name: "Vallabhi Engineering Services",
      description:
        "Vallabhi Engineering Services provides expert solutions in design, consultancy, and project management. We specialize in interior fit-outs, electrical work, IT networking, CCTV, and solar rooftop solutions.",
      image: eng,
    },
    {
      name: "Vallabhi Agro Services",
      description:
        "Vallabhi Agro specializes in FMCG products like spices, grains, and oils. We engage in contract farming for produce, silage, tobacco, and GNS, ensuring sustainable, high-quality products.",
      image: farmer,
    },
    {
      name: "Vallabhi Impex Services",
      description:
        "Vallabhi Impex handles import/export of food products, engineering goods, medical apparatus, chemicals, cosmetics, and oils. We ensure compliance, timely delivery, and efficient supply chain management.",
      image: impo_expo,
    },
    {
      name: "Vallabhi Green Foundation",
      description:
        "Vallabhi Green Foundation promotes ethical cow care, environmental sustainability, and green energy. We focus on species protection, water conservation, sustainable farming, and education for the underprivileged.",
      image: cow,
    },
    {
      name: "Vallabhi Spark Reality",
      description:
        "Vallabhi Construction Services offers expert project management for residential, commercial, and industrial construction. We deliver safe, reliable, and timely results using advanced techniques and quality materials.",
      image: construction,
    },
    {
      name: "Vallabhies  Diagnostic",
      description:
        "Vallabhi Diagnostic provides accurate, reliable diagnostic services, including blood work, imaging, and pathology. Using state-of-the-art technology, we ensure precise results through our specialized testing centers.",
      image: diagonostics,
    },
    {
      name: "Kleem Hospitals",
      description:
        "Kleem Hospitals delivers high-quality, cost-efficient healthcare with advanced technology across 100-bed facilities. Our patient-centered approach ensures effective treatment and superior outcomes in tier 1 and tier 2 cities.",
      image: hospitals,
    },
    {
      name: "Vallabhi Phygital Telmeds",
      description:
        "Vallabhi Phygital Telmeds provides telemedicine solutions and pharmaceutical distribution. We focus on telecommunication, reliable delivery of generic and branded medicines, and enhancing healthcare accessibility.",
      image: drugs,
    },
    {
      name: "Vallabhi Drvya",
      description:
        "Vallabhi Drvya offers financial services including investment advisory, wealth management, and blockchain-based payment solutions. We provide credit lines for both urban and rural clients, ensuring secure financial growth.",
      image: financial,
    },
    {
      name: "Vallabhi IT Services",
      description:
        "Vallabhi IT Services specializes in software development, UI/UX design, and integration. We offer SaaS, PaaS, and annual maintenance services, delivering innovative technology solutions tailored to client needs.",
      image: software,
    },
    {
      name: "Narad",
      description:
        "The Narad provides real-time updates on global and local events, covering politics, entertainment, sports, and business. It offers engaging articles, videos, and multimedia content, with a responsive design for seamless mobile and desktop access.",
      image: narad,
    },
    {
      name: "Bani Digital Media",
      description:
        "Bani Digital Media provides targeted online advertising services, focusing on global and local audiences. We offer real-time analytics, flexible campaign management, and customized strategies for effective local consumer engagement.",
      image: bani,
    },
   
  ];

  return (
    <div className="readmore py-8 px-4 lg:px-1">
        <SubHeader/>
      {/* Section: Meet Our Visionary */}

      <div className="flex items-center justify-center m-8 p-8 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951] ">Meet Our Visionary</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="bg-[#ffd2b3]  shadow-xl shadow-black p-4 rounded-lg">
        <div className="flex flex-col lg:flex-row justify-between items-center py-8">
          <div className="text-gray-800 text-lg font-bold lg:font-bold lg:text-lg lg:w-1/2 sm:w-full sm:text-sm">
            <p className="px-8 py-4 lg:py-0">
              Mr. Narendra Shukla is a proficient leader with social, political
              and technical expertise. He upholds high ethical values and is
              knowledgeable in management, economics and politics. With
              excellent communication abilities, he comprehends various public
              policy issues like economics, social welfare, and national
              security, to make well-informed decisions.
            </p>
          </div>

          <div className="flex justify-center lg:w-1/2 sm:w-full mt-4 lg:mt-0">
            <img
              src={image}
              alt="Mr. Narendra Shukla"
              className="rounded-lg shadow-lg w-80 lg:w-96"
            />
          </div>
        </div>

        <div className="my-8">
          <p className="mt-4">
            Mr. Shukla is a seasoned professional with extensive experience in
            Facilities Management and Real Estate portfolio. He was the Director
            of Lilac Facilities Management Private Limited, where he led the
            strategizing efforts to generate value and align resources to meet
            business objectives while remaining compliant with the law of the
            land.
          </p>
          <p className="mt-4">
            He also served as the Facility Head at Airtel India, where he
            demonstrated his ability to manage partners across the CRE domain
            and oversee the delivery of services and projects such as Network
            Data Centre, MSCs, Offices & Campuses.
          </p>
          <p className="mt-4">
            Mr. Shukla began his career in the Indian Navy as an Aircraft
            Maintenance Engineer, where he specialized in the maintenance of
            aircraft navigational systems of Russian origin aircraft TU142M. He
            received his Electrical Engineering degree with a specialization in
            Aviation from the Naval Institute of Aviation Training in 1988.
          </p>
        </div>
      </div>

      {/* Section: About Vallabhiindia */}

      <div className="flex items-center justify-center m-8 p-8 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951] ">
          About Vallabhiindia
        </p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>
      <div className="bg-[#ffd2b3]  shadow-xl shadow-black px-2 py-8 rounded-lg">
        {" "}
        <p className="px-4">
          Committed to environmental conservation and sustainable development,
          Vallabhi Green Foundation runs various green initiatives, including a
          gaushala dedicated to the welfare of cows. Our gaushala is a sanctuary
          that provides a safe and nurturing environment for cows, emphasizing
          their care, health, and well-being. It reflects our dedication to
          preserving indigenous cattle breeds and promoting sustainable
          agricultural practices. Vallabhiindia is a dynamic umbrella company
          that oversees a diverse portfolio of enterprises, each excelling in
          its respective field. Our commitment to excellence, innovation, and
          community welfare drives every initiative under our brand.
        </p>
      </div>

      {/* Section: Our Companies */}
      <div className="">
        <div className="flex items-center justify-center m-8 p-8 space-x-4">
          <img className="h-8 w-auto" src={icon1} alt="icon1" />
          <p className="text-2xl font-bold text-[#F17951] ">Our Companies</p>
          <img className="h-8 w-auto" src={icon2} alt="icon2" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
          {vallabhiServices.map((detail, index) => {
            return (
              <div
                key={index}
                className=" p-4 shadow-lg shadow-black rounded-md bg-[#ffd2b3] "
              >
                <img
                  src={detail.image}
                  className="w-full h-60 object-cover rounded-lg mb-4"
                  alt={detail.name}
                ></img>
                <h1 className="text-xl font-bold mb-2">{detail.name}</h1>
                <p className="text-gray-700">{detail.description}</p>
              </div>
            );
          })}

        </div>
      </div>

      {/* Section: Vision */}

      <div className="flex items-center justify-center m-8 p-8 space-x-4">
          <img className="h-8 w-auto" src={icon1} alt="icon1" />
          <p className="text-2xl font-bold text-[#F17951] ">Our Vision</p>
          <img className="h-8 w-auto" src={icon2} alt="icon2" />
        </div>

      <div className="bg-[#ffd2b3]  shadow-xl shadow-black p-4 rounded-lg">
        <p>
          At Vallabhiindia, our vision is to create a positive impact across
          diverse industries, fostering growth, innovation, and sustainability.
          We strive to be a trusted name in every sector we operate in,
          dedicated to improving lives and building a better future.
        </p>
      </div>
    </div>
  );
};

export default AboutRead;
