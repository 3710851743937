import React, { useState, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import { useTranslation } from "react-i18next";
import SubHeader from "../components/SubHeader";

const Testimonials = ({ showSubHeader = true }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      image:
        "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215105/test_Api/ol4qgmg7woogas2b2czw.png",
      text: t("testimonial1.text"),
      name: t("testimonial1.name"),
      title: t("testimonial1.title"),
    },
    {
      image:
        "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215175/test_Api/jvuuhyyehqldvjgilulz.png",
      text: t("testimonial2.text"),
      name: t("testimonial2.name"),
      title: t("testimonial2.title"),
    },
    {
      image:
        "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215383/test_Api/trwton1bwdp9ac421wtd.png",
      text: t("testimonial3.text"),
      name: t("testimonial3.name"),
      title: t("testimonial3.title"),
    },
    {
      image:
        "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215313/test_Api/hcdnao8mm3mrgmpmqcxp.jpg",
      text: t("testimonial4.text"),
      name: t("testimonial4.name"),
      title: t("testimonial4.title"),
    },
    {
      image:
        "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215463/test_Api/z4monsbpi3eijirvuzxu.jpg",
      text: t("testimonial5.text"),
      name: t("testimonial5.name"),
      title: t("testimonial5.title"),
    },
  ];

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  // Function to handle navigation
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="text-center p-4 md:p-8 " id="ourWork">
      {showSubHeader && <SubHeader />}

      <div className="flex items-center justify-center m-4 p-4 space-x-4">
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-sm sm:text-lg lg:text-2xl font-bold text-[#F17951]">
          {t("testimonials")}</p>
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <section className="relative md:mb-4  md:mx-12 lg:mx-20 ">
        <div className="flex flex-col  lg:flex-row items-center p-4 mx-8 bg-[#ffd2b3] rounded-lg h-full">
          {/* Image Section */}
          <div className="flex justify-center lg:justify-center mb-4 lg:mb-0 lg:mr-8">
            <div className="w-24 h-24 md:w-40 md:h-40 ">
              <img
                src={testimonials[currentIndex].image}
                alt={`testimonial ${currentIndex + 1}`}
                className="w-full h-full rounded-full object-cover shadow-md shadow-black bg-white"
              />
            </div>
          </div>

          {/* Text Section */}
          <div className="flex-1 text-center lg:text-left px-12 py-8">
            <p className="mb-2 text-sm md:text-base">{testimonials[currentIndex].text}</p>
            <h2 className="text-md md:text-lg font-semibold text-center">
              {testimonials[currentIndex].name}
            </h2>
            <h5 className="text-xs md:text-sm text-gray-500 text-center">
              {testimonials[currentIndex].title}
            </h5>
          </div>
        </div>

        {/* Custom Navigation Buttons */}
        <div
          ref={prevRef}
          onClick={handlePrev}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer text-[#F17951] bg-white p-2 rounded-full shadow-lg z-10"
        >
          <FaArrowLeft size={24} className="" />
        </div>
        <div
          ref={nextRef}
          onClick={handleNext}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer text-[#F17951] bg-white p-2 rounded-full shadow-lg z-10"
        >
          <FaArrowRight size={24} />
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
