import React, { useState, useEffect } from 'react';
import { MhahPanchang } from 'mhah-panchang';
import { useParams } from 'react-router-dom';

const Panchang = () => {
  const { date: dateParam } = useParams(); // Get the date from URL
  const [panchangData, setPanchangData] = useState(null);
  const [latitude, setLatitude] = useState(12.972); // Default latitude
  const [longitude, setLongitude] = useState(77.594); // Default longitude
  const [date, setDate] = useState(new Date(dateParam)); // Initialize date with URL date

  useEffect(() => {
    const obj = new MhahPanchang();
    const calculatedData = obj.calculate(date);
    const calendarData = obj.calendar(date, latitude, longitude);
    setPanchangData({ calculatedData, calendarData });
  }, [date, latitude, longitude]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#F0DCD5] p-6">
      <div className="w-full sm:w-3/4 lg:w-2/3 bg-[#ffd2b3] shadow-lg rounded-lg p-6">
        <h1 className="text-4xl font-bold text-center mb-6 text-[#F17951]">Hindu Panchang</h1>

        <div className="flex justify-between items-center gap-4 mb-6">
          <div>
            <h2 className="text-2xl mb-2">Current Date</h2>
          </div>

          <div className="">
            <img className='h-28 w-28' src="https://lh3.googleusercontent.com/proxy/44xJeFSu5TTOwC-Tcwq3m8j19TNRGDSKFQHZCQhrZHmVuglD17feBSfUOGcah3vbAMuLvtxzYVs6DNCZSoieMScNamgxyw"></img>
          </div>
          <div>
            <input
              type="date"
              value={date.toISOString().split("T")[0]} // Format date for input
              onChange={(e) => setDate(new Date(e.target.value))}
              className="border rounded p-2"
            />
          </div>
        </div>

        {panchangData && (
          <div>
            <h2 className="text-2xl mb-4 text-center">Panchang Details</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {/* Creating individual grid items */}
              {Object.entries(panchangData.calendarData).map(([key, value]) => (
                <div key={key} className="bg-white border border-gray-300 p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                  <p className="text-gray-700">{value.name_en_IN || value.name_en_UK}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Panchang;
