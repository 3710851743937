import React, { useEffect, useState } from 'react';
import { socket } from '../components/socket';

const WebsiteLiveVisitors = () => {
    const [liveVisitorCount, setLiveVisitorCount] = useState(60);
    const [displayCount, setDisplayCount] = useState(0); // For the smooth increment animation

    useEffect(() => {
        // Function to smoothly increment the displayed count
        const incrementCount = (start, end) => {
            let current = start;
            const increment = end > start ? Math.ceil((end - start) / 50) : 0; // Adjust speed

            const updateCount = () => {
                if (current < end) {
                    current += increment;
                    setDisplayCount(current);
                    requestAnimationFrame(updateCount);
                } else {
                    setDisplayCount(end); // Ensure we hit the target number
                }
            };
            updateCount();
        };

        function countEvent(value) {
            const newVisitorCount = 60 + value.count;
            setLiveVisitorCount(newVisitorCount);
            incrementCount(displayCount, newVisitorCount); // Start smooth increment
        }

        socket.on('usercount', countEvent);
        return () => {
            socket.off('usercount', countEvent);
        };
    }, [displayCount]);

    return (
        <div className="flex flex-col items-center justify-center bg-gradient-to-r from-[#F17951] via-[#ffd2b3] to-[#F17951] p-6 rounded-xl shadow-lg mx-8 md:mt-4 mt-4 transform transition-transform duration-500 hover:scale-105">
            <h2 className="text-3xl font-extrabold text-black mb-2 tracking-wider animate-bounce">
                Live Visitors
            </h2>
            <span className="text-5xl font-extrabold text-[#F17951] drop-shadow-lg animate-pulse">
                {displayCount}+
            </span>
        </div>
    );
};

export default WebsiteLiveVisitors;
