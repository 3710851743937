import React from 'react';

function AnyAmountCow() {
    const items = [
        { id: 1, type: "donate_product", title: 'Monthly Care Of A Cow', price: 2100, unit: "per cow per month", text: "We provide comprehensive care for your adopted gauvansh, ensuring their shelter, food, medicines, and daily needs are met. This initiative helps save the precious lives of our mother cows, alleviating concerns about their micromanagement. Your support makes a meaningful difference in their well-being." },
        { id: 2, type: "donate_product", title: 'Monthly Care Of A Milk Giving Cows', price: 3500, unit: "per cow per month", text: "We provide full care for your adopted milk-giving cows, addressing all their daily needs. Their milk is freely distributed to saints and those in need. This initiative saves the precious lives of our mother cows while ensuring their milk nourishes those who require it. Your support truly matters." },
        { id: 3, type: "donate_product", title: 'Monthly Care Of A Calf', price: 1100, unit: "per calf per month", text: "We provide comprehensive care for your adopted calf under two years of age, ensuring their shelter, food, medicines, and daily needs are met. This initiative saves the precious life of the calf, relieving you of any concerns about micromanagement. Your support makes a significant difference in their well-being." },
        { id: 4, type: "donate_product", title: 'Monthly Care Of A Bull Or Ox', price: 3000, unit: "per bull per month", text: "We provide full care for your adopted bull, ensuring their shelter, food, medicines, and daily needs are met. This initiative saves the precious life of the bull, allowing you to relax without concerns about micromanagement. Your support is vital in enhancing their well-being." },

    ];

    return (
        <div className='container mx-auto p-6 bg-[#ffd2b3] rounded-lg shadow-lg'>
            <h1 className='text-3xl font-bold text-center mb-6'>Any Amount for Cow</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {items.map((item) => {
                    return (<div key={item.id} className="bg-white rounded-lg shadow-md p-4 text-center">
                        <h3 className="font-semibold text-lg">{item.title}</h3>
                        <p className="text-xl font-bold text-gray-700">Rs {item.price}/-</p>
                    </div>)


                })}
            </div>
        </div>
    );
}

export default AnyAmountCow;
