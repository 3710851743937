import React from 'react';

function Construction() {
    const items = [
        { id: 1, title: 'Green Fodder', price: 500 },
        { id: 2, title: 'Gud-jaggery', price: 800 },
        { id: 3, title: 'Khal - Mustard Cake', price: 850 },
        { id: 4, title: 'Chokar - Wheat Bran', price: 600 },
        { id: 5, title: 'Channa Churi', price: 1000 },
        { id: 6, title: 'Husk - Wheat Straw', price: 700 },
    ];

    return (
        <div className='container mx-auto p-6 bg-[#ffd2b3] rounded-lg shadow-lg'>
            <h1 className='text-3xl font-bold text-center mb-6'>For Construction</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {items.map(item => (
                    <div key={item.id} className="bg-white rounded-lg shadow-md p-4 text-center">
                        <h3 className="font-semibold text-lg">{item.title}</h3>
                        <p className="text-xl font-bold text-gray-700">Rs {item.price}/-</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Construction;
