import React, { useContext } from "react";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import ChannaChuri from "../assests/donationForCowImage/ChannaChuri.jpg";
import fooder from "../assests/donationForCowImage/fooder.jpeg";
import husk from "../assests/donationForCowImage/husk.jpg";
import GudJaggery from "../assests/donationForCowImage/GudJaggery.png";
import mustardCake from "../assests/donationForCowImage/khal.jpg";
import wheatBran from "../assests/donationForCowImage/wheatBran.jpeg";
import SubHeader from "../components/SubHeader";
import { CartContext } from "../components/Context";

const Food = () => {
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  const items = [
    {
      id: 1,
      type: "donate_product",
      image: fooder,
      title: "Green Fodder",
      price: 500,
      unit: "A Unit Is Of 100 Kg",
      text: "Green crops include legume, cereal, and grass crops. They serve as the primary and preferred food for cows, being highly palatable and digestible. Over 2 million kg is needed monthly.",
    },
    {
      id: 2,
      type: "donate_product",
      image: GudJaggery,
      title: "Gud-Jaggery",
      price: 800,
      unit: "Bag Is Of 10 Kg",
      text: "A sweet solid food substance obtained from the juice of sugarcane. It strengthens immunity, contains iron, and keeps animals healthy and fit. Approx. 10 tonnes is needed monthly.",
    },
    {
      id: 3,
      type: "donate_product",
      image: mustardCake,
      title: "Khal-Mustard Cake",
      price: 850,
      unit: "Bag Is Of 20 Kg",
      text: "The solid residue left after oil is extracted from mustard seeds. It is good for health and provides strength to cattle. Approx. 10 tonnes is needed monthly.",
    },
    {
      id: 4,
      type: "donate_product",
      image: wheatBran,
      title: "Chokar-Wheat Bran",
      price: 600,
      unit: "Bag Is Of 20 Kg",
      text: "The outer shell of wheat is called bran. It is a great source of fiber and helps keep the digestive system healthy. More than 100,000 kg is needed monthly.",
    },
    {
      id: 5,
      type: "donate_product",
      image: ChannaChuri,
      title: "Channa Churi",
      price: 1000,
      unit: "Bag Is Of 15 Kg",
      text: "Chickpea, also known as gram, is an annual legume with seeds high in protein and various nutrients. It provides a healthy diet, offering significant strength and stamina to cattle. More than 5 tonnes is needed monthly.",
    },
    {
      id: 6,
      type: "donate_product",
      image: husk,
      title: "Husk -Wheat Straw",
      price: 700,
      unit: "A Unit Is Of 100 Kg",
      text: "Husk, or wheat straw, is the stalk that remains after the wheat grain is harvested. It is essential for good ruminant feeding and helps fill the stomach. More than 1 million kg is needed monthly.",
    },
  ];

  return (
    <>
      <SubHeader />
      <div className="bg-[#ffd2b3] shadow-lg shadow-black m-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {items.map((item) => (
            <div
              key={item.id}
              className="border rounded-lg p-4 text-center shadow-md shadow-black flex flex-col justify-between"
            >
              <div>  <img
                src={item.image}
                alt={item.title}
                className="w-full h-60 object-cover mb-2 rounded"
              />
                <h3 className="font-semibold text-lg">{item.title}</h3>
                <p className="text-gray-600">{item.text}</p></div>

              <div className="flex justify-between my-4">
                <div>
                  <h1 className="text-lg font-bold">{item.price}/- </h1>
                  <h3>{item.unit}</h3>
                </div>
                <div className="bg-[#F17951] flex justify-between items-center rounded-lg">
                  <button
                    className="px-4"
                    onClick={() => removeFromCart(item.id)}
                  >
                    <FiMinus className="border-2 border-black" />
                  </button>
                  {/* Updated logic to display quantity */}
                  <p>
                    {cartItems.find((cartItem) => cartItem.id === item.id)
                      ?.quantity || 0}
                  </p>
                  <button className="px-4" onClick={() => addToCart(item)}>
                    <IoMdAdd className="border-2 border-black" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Food;
