import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: "Welcome to our website",
      HinduCalendar:"Hindu Calendar",
      join_us:"Join us",
      description: "This is a multi-language site",
      menu: "Menu",
      title: "Shrinarayan Vrihad Gaushala",
      home: "Home",
      about_us: "About Us",
      contact_us: "Contact Us",
      login:"Login",
      donate_now: "Donate Now",
      choose_language: "Language",
      gallery: "Gallery",
      our_work: "Our Work",
      our_products:"Our Products",
      BecomeGausevak:"Become a Gausevak",
      VisitGaushala:"Visit Gaushala",

      testimonial: "Testimonial",
      our_activities: "Our Activities",
      benefits_of_cow: "Benefits of Cow",
      faq: "FAQ",
      feedback: "Feedback",
      service_to_mother_cow: "Service To Mother Cow",
      for_food: "For Food",
      monthly_care: "Monthly Care Of A Cow",
      medicines: "Medicines",
      testimonials: "Testimonials",
      close: "Close",
      open: "Open",

      description_about: `Shrinarayan Vrihad Gaushala, owned by Vallabhi Green Foundation,
      began its journey in 2023 in Jaunpur with just 20 cows. Today, we
      are proud to shelter and care for over 400 cows and bulls. Our
      mission is to protect, feed, and provide shelter to ailing,
      abandoned, and stray indigenous cows and bulls. Many of these
      animals have been rescued from difficult circumstances, and they
      receive round-the-clock care from our dedicated team of Gausewaks,
      including proper medical attention.
      Our Gaushala operates under the guidance of compassionate leaders,
      ensuring that these cows are treated with utmost respect and care.
      We do not exploit them for milk production but distribute it freely
      to saints, ascetics, volunteers, and ashrams in need. As our efforts
      continue, the number of cows and bulls entrusted to our care grows
      steadily, reflecting our commitment to their welfare and protection.`,
      readMore: "Read More",

      title2: "What we do",
      shelter: {
        subtitle: "Shelter",
        text: "We have a large, well-equipped facility offering safe and comfortable shelters for rescued cows. Our infrastructure includes spacious cow sheds with proper ventilation, sanitation, and feeding areas, and we continually expand as the number of cows grows. We ensure cleanliness and hygiene to prevent health issues and provide regular upgrades to maintain a peaceful environment for each cow."
      },
      treatment: {
        subtitle: "Treatment",
        text: "We have a dedicated team of veterinarians available 24/7, equipped with advanced diagnostic tools and treatment equipment. Our in-house medical facilities handle everything from routine check-ups to emergencies, with a full inventory of essential medicines and vaccinations. We focus on personalized care, preventive measures, and specialized treatments, continually updating our practices to ensure the highest standard of care for our cows."
      },
      freeMilkDistribution: {
        subtitle: "Milk Distribution",
        text: "At our Gaushala, we produce a limited quantity of milk, which we distribute free of charge to local ashrams, charitable hospitals, and community organizations. This effort is part of our commitment to community support and well-being. By providing fresh milk, we aim to enhance local health and strengthen community ties. Our team ensures that the milk is distributed efficiently and respectfully, maximizing its benefits for those in need."
      },
      protectionDrives: {
        subtitle: "Protection Drives",
        text: "We actively engage in rescue operations, retrieving injured and stray cows and bulls, bringing them to our Gaushala for rehabilitation. Upon arrival, the animals are triaged for immediate medical attention, provided with a secure environment tailored to their recovery needs. Our team offers a comprehensive care plan, including specialized feed, vaccinations, and continuous veterinary monitoring. These efforts are supported by well-maintained facilities and 24/7 supervision, ensuring that each animal receives optimal care and support throughout their recovery journey."
      },
      protectionToCows: {
        subtitle: "Protection to Cows",
        text: "Our dedicated team ensures the well-being of each cow by providing a safe shelter, nutritious food, and round-the-clock care. We maintain a fully equipped medical facility on-site, ready to address any health issues promptly. Each cow benefits from our comprehensive care program, which includes regular health check-ups, vaccinations, and tailored nutrition plans to support their overall health and recovery. Our commitment extends to maintaining a clean and comfortable living environment, promoting the best possible quality of life for all our residents."
      },
      breeding: {
        subtitle: "Breeding",
        text: "We are actively researching to enhance the breeding of indigenous cows to increase their milk yield and overall quality. Our breeding programs focus on improving essential traits such as disease resistance, adaptability to local environments, and higher milk production. By selectively breeding cows and bulls with these desirable characteristics, we aim to foster a sustainable ecosystem that benefits farmers and encourages the adoption of these high-quality cows. Our goal is to create a positive societal impact through improved livestock, ultimately supporting local agriculture and advancing the well-being of communities."
      },
      training: {
        subtitle: "Training",
        text: "We conduct vocational training programs to emphasize the value of desi cows and bulls and their role in sustainable agriculture. These programs offer practical skills in producing products such as dhoop, phenyl, and cosmetics. Additionally, we provide training to promote organic farming practices in India. Our aim is to empower farmers with knowledge and skills to support the preservation of cultural heritage through desi cattle and to advocate for organic farming and sustainable agricultural practices."
      },
      cultivationOfGreenFodder: {
        subtitle: "Feeding of Green Fodder ( Silage )",
        text: "We cultivate a variety of green fodder to ensure a steady and reliable supply for our cows, addressing market shortages. This initiative guarantees that our cattle receive consistent and balanced nutrition, supporting their overall health and well-being. By growing diverse fodder crops and nutrient-rich plants, we cater to the specific dietary needs of our cows, helping us manage fluctuating fodder availability effectively and providing the nourishment essential for their well-being."
      },


      testimonial1: {
        text: "I recently had the pleasure of visiting Shrinarayan Vrihad Gaushala, and it was a truly heartwarming experience. The dedication and care provided to the cows here are exemplary. The gaushala is well-maintained, ensuring a clean and comfortable environment for the animals.",
        name: "Shivam",
        title: "Data Scientist",
      },
      testimonial2: {
        text: "Visiting Shrinarayan Vrihad Gaushala was not only an educational experience but also a deeply fulfilling one. I highly recommend it to anyone interested in learning more about the importance of cow welfare and sustainable farming practices. Kudos to the entire team for their hard work and dedication!",
        name: "Rahul Kumar",
        title: "Product Manager (Paytm)",
      },
      testimonial3: {
        text: "I recently had the pleasure of visiting Shrinarayan Vrihad Gaushala, and it was a truly heartwarming and enriching experience. The dedication and care provided to the cows here are exemplary. The gaushala is impeccably maintained, ensuring a clean and comfortable environment.",
        name: "Rohan Srivastava",
        title: "CA",
      },
      testimonial4: {
        text: "One of the standout features of Shrinarayan Vrihad Gaushala is its focus on sustainable and eco-friendly practices. The gaushala utilizes organic feed, employs green energy solutions, implements innovative waste management techniques, and engages in community outreach programs to promote environmental awareness.",
        name: "Geet Shukla",
        title: "CXO (Vallabhi Groups)",
      },
      testimonial5: {
        text: "Visiting Shrinarayan Vrihad Gaushala was not only an educational experience but also a deeply fulfilling one. It is a place where compassion, sustainability, and tradition come together to create a sanctuary for cows. The supportive staff and tranquil setting enhance the overall experience.",
        name: "Shashwat Mishra",
        title: "Gausevak",
      },
    }
  },
  hi: {
    translation: {
      welcome: "हमारी वेबसाइट पर आपका स्वागत है",
      HinduCalendar:"हिंदू कैलेंडर",
      join_us:"हमसे जुड़ें",

      description: "यह एक बहुभाषी साइट है",
      menu: "मेनू",
      title: "श्रीनारायण वृहद गौशाला",
      home: "होम",
      about_us: "हमारे बारे में",
      contact_us: "संपर्क करें",
      login:"लॉग इन करें",
      donate_now: "अब दान करें",
      choose_language: "भाषा चुनें",
      gallery: "गैलरी",
      our_work: "हमारा कार्य",
      our_products:"हमारे उत्पाद",
      
      VisitGaushala:"हमारी गौशाला पधारें",
      BecomeGausevak:"गौसेवक बनो",


      testimonial: "प्रस्तुतियाँ",
      our_activities: "हमारी गतिविधियाँ",
      benefits_of_cow: "गाय के लाभ",
      faq: "अक्सर पूछे जाने वाले प्रश्न",
      feedback: "प्रतिक्रिया",
      service_to_mother_cow: "माँ गाय की सेवा",
      for_food: "खाने के लिए",
      monthly_care: "गाय की मासिक देखभाल",
      medicines: "दवाएँ",
      testimonials: "प्रस्तुतियाँ",
      close: "बंद करें",
      open: "खोलें",

      description_about: `श्रीनारायण वृहद गौशाला, जो वल्लभी ग्रीन फाउंडेशन द्वारा संचालित है,
      2023 में जौनपुर में केवल 20 गायों के साथ अपनी यात्रा शुरू की। आज, हम
      400 से अधिक गायों और बैलों की देखभाल करने में गर्व महसूस करते हैं। हमारा
      मिशन बीमार, abandoned, और सड़क पर भटकने वाली देशी गायों और बैलों की सुरक्षा,
      भोजन प्रदान करना और आश्रय देना है। इनमें से कई जानवरों को कठिन परिस्थितियों
      से बचाया गया है, और उन्हें हमारे समर्पित गऊसेवकों की टीम द्वारा
      24 घंटे देखभाल मिलती है, जिसमें उचित चिकित्सा देखभाल शामिल है।
      हमारी गौशाला दयालु नेताओं के मार्गदर्शन में संचालित होती है,
      यह सुनिश्चित करते हुए कि इन गायों का अत्यधिक सम्मान और देखभाल की जाती है।
      हम उन्हें दूध उत्पादन के लिए शोषित नहीं करते हैं, बल्कि जरूरतमंद संतों,
      साधुओं, स्वयंसेवकों, और आश्रमों में इसे मुफ्त में वितरित करते हैं। जैसे-जैसे
      हमारे प्रयास जारी हैं, हमारे पास आए गायों और बैलों की संख्या लगातार बढ़ती
      जा रही है, जो उनकी कल्याण और सुरक्षा के प्रति हमारी प्रतिबद्धता को दर्शाता है।`,
      readMore: "और पढ़ें",


      title2: "हम क्या करते हैं",
      shelter: {
        subtitle: "शेल्टर",
        text: "हमारे पास एक बड़ा, अच्छी तरह से सुसज्जित सुविधाएँ हैं जो बचाए गए गायों के लिए सुरक्षित और आरामदायक आश्रय प्रदान करती हैं। हमारी अवसंरचना में उचित वेंटिलेशन, स्वच्छता और फ़ीड क्षेत्रों के साथ विस्तृत गाय के शेड शामिल हैं, और जैसे-जैसे गायों की संख्या बढ़ती है, हम निरंतर विस्तार करते हैं। हम स्वास्थ्य समस्याओं से बचने के लिए स्वच्छता और स्वच्छता सुनिश्चित करते हैं और प्रत्येक गाय के लिए शांतिपूर्ण वातावरण बनाए रखने के लिए नियमित अपग्रेड प्रदान करते हैं।"
      },
      treatment: {
        subtitle: "उपचार",
        text: "हमारे पास 24/7 उपलब्ध वेटरिनियर्स की एक समर्पित टीम है, जो उन्नत नैदानिक उपकरणों और उपचार उपकरणों से लैस है। हमारी इन-हाउस चिकित्सा सुविधाएँ नियमित चेक-अप से लेकर आपात स्थितियों तक सब कुछ संभालती हैं, आवश्यक दवाओं और टीकों का पूरा इन्वेंट्री है। हम व्यक्तिगत देखभाल, निवारक उपायों और विशेष उपचारों पर ध्यान केंद्रित करते हैं, उच्चतम मानक की देखभाल सुनिश्चित करने के लिए अपनी प्रथाओं को लगातार अपडेट करते हैं।"
      },
      freeMilkDistribution: {
        subtitle: "नि:शुल्क दूध वितरण",
        text: "हमारे गोशाला में, हम दूध की एक सीमित मात्रा का उत्पादन करते हैं, जिसे हम स्थानीय आश्रमों, चैरिटेबल अस्पतालों और सामुदायिक संगठनों को मुफ्त में वितरित करते हैं। यह प्रयास हमारी सामुदायिक समर्थन और कल्याण के प्रति प्रतिबद्धता का एक हिस्सा है। ताजा दूध प्रदान करके, हम स्थानीय स्वास्थ्य को बढ़ाने और सामुदायिक संबंधों को मजबूत करने का लक्ष्य रखते हैं। हमारी टीम यह सुनिश्चित करती है कि दूध कुशलता से और सम्मानपूर्वक वितरित किया जाए, जरूरतमंदों के लिए इसके लाभों को अधिकतम किया जाए।"
      },
      protectionDrives: {
        subtitle: "संरक्षण अभियान",
        text: "हम सक्रिय रूप से बचाव संचालन में शामिल हैं, घायल और आवारा गायों और बैल को उठाते हैं, उन्हें पुनर्वास के लिए हमारे गोशाला लाते हैं। आगमन पर, जानवरों को तत्काल चिकित्सा ध्यान के लिए वर्गीकृत किया जाता है, उन्हें उनकी पुनर्प्राप्ति आवश्यकताओं के लिए एक सुरक्षित वातावरण प्रदान किया जाता है। हमारी टीम एक व्यापक देखभाल योजना प्रदान करती है, जिसमें विशेष फ़ीड, टीकाकरण और निरंतर पशु चिकित्सा निगरानी शामिल है। ये प्रयास अच्छी तरह से बनाए रखी गई सुविधाओं और 24/7 पर्यवेक्षण द्वारा समर्थित हैं, यह सुनिश्चित करते हुए कि प्रत्येक जानवर को उनकी पुनर्प्राप्ति यात्रा के दौरान सर्वोत्तम देखभाल और समर्थन मिले।"
      },
      protectionToCows: {
        subtitle: "गायों की सुरक्षा",
        text: "हमारी समर्पित टीम प्रत्येक गाय की भलाई सुनिश्चित करती है, सुरक्षित आश्रय, पौष्टिक भोजन, और चौबीसों घंटे देखभाल प्रदान करती है। हम साइट पर पूरी तरह से सुसज्जित चिकित्सा सुविधा बनाए रखते हैं, जो किसी भी स्वास्थ्य समस्याओं को तुरंत संबोधित करने के लिए तैयार है। प्रत्येक गाय हमारे व्यापक देखभाल कार्यक्रम का लाभ उठाती है, जिसमें नियमित स्वास्थ्य चेक-अप, टीकाकरण, और उनके समग्र स्वास्थ्य और पुनर्प्राप्ति का समर्थन करने के लिए अनुकूलित पोषण योजनाएँ शामिल हैं। हमारी प्रतिबद्धता एक साफ और आरामदायक रहने का वातावरण बनाए रखने तक फैली हुई है, जो हमारे सभी निवासियों के लिए सबसे अच्छी संभव जीवन की गुणवत्ता को बढ़ावा देती है।"
      },
      breeding: {
        subtitle: "प्रजनन",
        text: "हम स्वदेशी गायों के प्रजनन को बढ़ाने के लिए सक्रिय रूप से शोध कर रहे हैं ताकि उनके दूध उत्पादन और समग्र गुणवत्ता को बढ़ाया जा सके। हमारे प्रजनन कार्यक्रम आवश्यक गुणों जैसे कि बीमारी प्रतिरोध, स्थानीय पर्यावरणों के लिए अनुकूलता, और अधिक दूध उत्पादन में सुधार पर ध्यान केंद्रित करते हैं। इन इच्छनीय विशेषताओं वाले गायों और बैल को चुनिंदा रूप से प्रजनन करके, हम एक स्थायी पारिस्थितिकी तंत्र को बढ़ावा देने का लक्ष्य रखते हैं जो किसानों के लिए लाभकारी है और इन उच्च गुणवत्ता वाली गायों को अपनाने को प्रोत्साहित करता है। हमारा लक्ष्य बेहतर पशुधन के माध्यम से सकारात्मक सामाजिक प्रभाव पैदा करना है, अंततः स्थानीय कृषि का समर्थन करना और समुदायों के कल्याण को बढ़ाना है।"
      },
      training: {
        subtitle: "प्रशिक्षण",
        text: "हम देसी गायों और बैल के मूल्य और सतत कृषि में उनकी भूमिका पर जोर देने के लिए व्यावसायिक प्रशिक्षण कार्यक्रम आयोजित करते हैं। ये कार्यक्रम धूप, फिनायल और सौंदर्य प्रसाधनों जैसे उत्पादों के उत्पादन में व्यावहारिक कौशल प्रदान करते हैं। इसके अलावा, हम भारत में जैविक खेती के प्रथाओं को बढ़ावा देने के लिए प्रशिक्षण प्रदान करते हैं। हमारा लक्ष्य किसानों को ज्ञान और कौशल से सशक्त बनाना है ताकि वे देसी मवेशियों के संरक्षण के माध्यम से सांस्कृतिक विरासत का समर्थन कर सकें और जैविक खेती और सतत कृषि प्रथाओं के लिए वकालत कर सकें।"
      },
      cultivationOfGreenFodder: {
        subtitle: "हरे चारे की खेती",
        text: "हम गायों के लिए एक स्थायी और विश्वसनीय आपूर्ति सुनिश्चित करने के लिए हरे चारे की विभिन्न किस्मों की खेती करते हैं, जो बाजार में कमी को दूर करता है। यह पहल सुनिश्चित करती है कि हमारे मवेशियों को निरंतर और संतुलित पोषण प्राप्त हो, जो उनके समग्र स्वास्थ्य और कल्याण का समर्थन करता है। विविध चारा फसलों और पोषक तत्वों से भरपूर पौधों की खेती करके, हम अपनी गायों की विशिष्ट आहार संबंधी आवश्यकताओं को पूरा करते हैं, हमें प्रभावी ढंग से चारे की उपलब्धता को प्रबंधित करने में मदद करते हैं और उनकी भलाई के लिए आवश्यक पोषण प्रदान करते हैं।"
      },

      testimonial1: {
        text: "मैं हाल ही में श्रीनारायण वृहद गौशाला जाने का सौभाग्य प्राप्त हुआ, और यह एक वास्तव में दिल को छू लेने वाला अनुभव था। यहाँ गायों को प्रदान की जाने वाली समर्पण और देखभाल अनुकरणीय है। गौशाला अच्छी तरह से बनाए रखा गया है, जो जानवरों के लिए एक स्वच्छ और आरामदायक वातावरण सुनिश्चित करता है।",
        name: "शिवम",
        title: "डेटा वैज्ञानिक",
      },
      testimonial2: {
        text: "श्रीनारायण वृहद गौशाला का दौरा न केवल एक शैक्षिक अनुभव था बल्कि एक गहन संतोषजनक भी। मैं इसे किसी भी व्यक्ति को अत्यधिक अनुशंसा करता हूँ जो गायों के कल्याण और सतत कृषि प्रथाओं के महत्व के बारे में अधिक जानने में रुचि रखते हैं। पूरी टीम को उनकी मेहनत और समर्पण के लिए बधाई!",
        name: "राहुल कुमार",
        title: "उत्पाद प्रबंधक (पे टम)",
      },
      testimonial3: {
        text: "मैं हाल ही में श्रीनारायण वृहद गौशाला जाने का सौभाग्य प्राप्त हुआ, और यह एक वास्तव में दिल को छू लेने वाला और समृद्ध अनुभव था। यहाँ गायों को प्रदान की जाने वाली समर्पण और देखभाल अनुकरणीय है। गौशाला उत्कृष्ट रूप से बनाए रखा गया है, जो एक स्वच्छ और आरामदायक वातावरण सुनिश्चित करता है।",
        name: "रोहन श्रीवास्तव",
        title: "सीए",
      },
      testimonial4: {
        text: "श्रीनारायण वृहद गौशाला की एक विशेषता इसका स्थायी और पारिस्थितिकीय रूप से अनुकूल प्रथाओं पर ध्यान केंद्रित करना है। गौशाला जैविक भोजन का उपयोग करती है, हरे ऊर्जा समाधान अपनाती है, नवीनतम अपशिष्ट प्रबंधन तकनीकों को लागू करती है, और पर्यावरण जागरूकता को बढ़ावा देने के लिए सामुदायिक आउटरीच कार्यक्रमों में भाग लेती है।",
        name: "गीत शुक्ला",
        title: "सीएक्सओ (वल्लभ समूह)",
      },
      testimonial5: {
        text: "श्रीनारायण वृहद गौशाला का दौरा न केवल एक शैक्षिक अनुभव था बल्कि एक गहन संतोषजनक भी। यह एक ऐसा स्थान है जहाँ करुणा, स्थिरता और परंपरा गायों के लिए एक आश्रय बनाने के लिए एक साथ आते हैं। सहायक स्टाफ और शांत वातावरण समग्र अनुभव को बढ़ाते हैं।",
        name: "शशवत मिश्रा",
        title: "गौसेवक",
      },
    }
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
