import React from "react";
import SubHeader from "../components/SubHeader";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
const PrivacyPage = () => {
  return (
    <div className="bg-gray-100">
      <SubHeader />
      <div className="flex items-center justify-center m-8 p-4 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951]">Refund Policy</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="shadow-lg shadow-black m-8 p-8 rounded-lg bg-[#ffd2b3]">
        <h2 className="text-xl font-semibold mb-4">Understanding Our Policy</h2>
        <p>
          At Shri Vrihad Narayan Gaushala, we value your generous support. All
          donations are final and non-refundable. Your contributions play a
          vital role in fulfilling our mission of caring for cows in need.
        </p>
        <h3 className="mt-6 text-lg font-semibold">Donation Acknowledgment</h3>
        <p>
          We appreciate your commitment to our cause. Every donation helps
          provide shelter, food, and medical care to the cows we rescue. Thank
          you for your trust in us!
        </p>
        <h3 className="mt-6 text-lg font-semibold">Issues with Donations</h3>
        <p>
          If you encounter any issues during the donation process, such as a
          payment getting stuck, please do not hesitate to reach out to us. Our
          team is dedicated to resolving any concerns you may have.
        </p>
        Contact our helpline at If you encounter any issues during the donation
        process, such as a payment getting stuck, please do not hesitate to
        reach out to us. Our team is dedicated to resolving any concerns you may
        have. Contact our helpline at{" "}
        <span className="font-bold text-green-600">
          {" "}
          <a href="https://wa.me/918595952508">+91 8595952508</a>{" "}
        </span>{" "}
        or email us at
        <span className="font-bold text-red-600">
          {" "}
          <a href="mailto:vgf@vallabhiindia.com"> vgf@vallabhiindia.com</a>
        </span>
        . We will promptly investigate and ensure a smooth and satisfactory
        experience for our donors.
        <h3 className="mt-6 text-lg font-semibold">Your Support Matters</h3>
        <p>
          Your understanding and support are invaluable to us. Together, we can
          continue to make a difference in the lives of the cows that depend on
          our care.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
