import React from "react";
import SubHeader from "../components/SubHeader";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";

const Disclaimer = () => {
  return (
    <div className="bg-gray-100">
      <SubHeader />
      <div className="flex items-center justify-center m-8 p-4 space-x-4">
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951]">Disclaimer</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="shadow-lg shadow-black m-8 p-8 rounded-lg bg-[#ffd2b3]">
        <h2 className="text-xl font-semibold mb-4">Welcome to Shri Vrihad Narayan Gaushala</h2>
        <p>
          Shri Vrihad Narayan Gaushala is a dedicated sanctuary committed to the welfare and protection of cows. Our mission is to provide safe shelter, essential medical treatment, and care for rescued cows, ensuring their well-being and dignity.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Our Commitment</h3>
        <p>
          We operate on the principles of compassion and respect for all living beings. Our team works tirelessly to create a nurturing environment for the cows in our care.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Donations and Support</h3>
        <p>
          We rely on the generous donations and support of our community to sustain our efforts. Your contributions directly impact the lives of the cows we rescue and care for. 
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide food and shelter for rescued cows.</li>
          <li>Offer medical care and treatment.</li>
          <li>Educate the community about animal welfare.</li>
        </ul>

        <h3 className="mt-6 text-lg font-semibold">Your Role</h3>
        <p>
          Join us in our endeavor to protect and nurture these gentle creatures. By contributing to our cause, you help us create a better world for them. Every donation, no matter the size, makes a difference.
        </p>

        <h3 className="mt-6 text-lg font-semibold">Disclaimer</h3>
        <p>
          The information provided on this website is for general informational purposes only. While we strive to maintain the accuracy of the information presented, we make no guarantees regarding its completeness or reliability. Please consult with a qualified professional for specific advice related to animal welfare or donations.
        </p>

        <p className="mt-6">
          Thank you for your understanding and support in our mission to safeguard the lives of cows and promote a compassionate community.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
