import React from 'react';
import FrontImage from "../assests/headerImage/poster.png"

const Home = () => {
  return (
    <div>
    <img className="w-full h-auto object-cover" src={FrontImage} alt="not found" />
  </div>
    
  );
};

export default Home;

