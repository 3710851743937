import React, { useState } from "react";
// import Home from "../components/Home";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png"; 
import SubHeader from "../components/SubHeader";

function Faq() {
  // State to manage which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // FAQ data
  const faqs = [
    {
      question:
        "What is the main Aim (Mission) of ShriNarayan Vrihad Gaushala?",
      answer:
        "ShriNarayan Vrihad GaushalaThe main aim of ShriNarayan Vrihad Gaushala is to protect indigenous (Desi) Gauvansh, including cows and bulls. As a non-profit organization, ShriNarayan Vrihad Gaushala is dedicated to providing shelter and medical care to stray cows and improving the health of infirm, unproductive, diseased, and abandoned Gauvansh.",
    },

    {
      question:
        "Do ShriNarayan Vrihad Gaushala keep all Gauvansh lifelong in shelter?",
      answer:
        "Yes, ShriNarayan Vrihad Gaushala provides lifelong care for all Gauvansh. Every Gauvansh that arrives at the Gaushala is sheltered and fully taken care of for the rest of its life, regardless of its condition.",
    },

    {
      question:
        "Do ShriNarayan Vrihad Gaushala protect injured and disabled Gauvansh?",
      answer:
        "Yes, ShriNarayan Vrihad Gaushala protects injured and disabled Gauvansh. The Gaushala's team of doctors and dedicated staff provide medical care and shelter to these Gauvansh for their entire lives.",
    },

    {
      question: "What does ShriNarayan Vrihad Gaushala do with all Gauvansh?",
      answer:
        "ShriNarayan Vrihad Gaushala is dedicated to protecting and sheltering indigenous cows. The Gaushala was founded with the aim of providing a peaceful and cruelty-free life for cows, ensuring their well-being and safety.",
    },

    {
      question:
        "Who looks after all cows and bulls in ShriNarayan Vrihad Gaushala?",
      answer:
        "In ShriNarayan Vrihad Gaushala, a dedicated team of professionals, including veterinarians and medical experts, provides the best possible care for all Gauvansh (cows and bulls). Additionally, a large team of gausewaks (cow caretakers) works around the clock to ensure that the cows receive the necessary food, shelter, and attention. Together, these professionals and volunteers create a safe and nurturing environment, significantly contributing to the welfare and protection of the cows.",
    },

    {
      question:
        "Is there any facility in-house in ShriNarayan Vrihad Gaushala?",
      answer:
        "Yes, ShriNarayan Vrihad Gaushala has comprehensive in-house facilities. The Gaushala includes well-equipped shelters with amenities such as an animal hospital, feed stores, and cowsheds. A dedicated team of doctors and numerous gausewaks work around the clock to ensure the health and well-being of the Gauvansh.",
    },

    {
      question: "Why does ShriNarayan Vrihad Gaushala shelter only Desi cows?",
      answer:
        "ShriNarayan Vrihad Gaushala focuses on sheltering only Desi cows and bulls because these Gauvansh are considered sacred in Hinduism and hold a significant place in Indian culture. Desi cows are revered as symbols of Mother Earth, providing nourishment through their milk and contributing to environmental balance with their unique properties. They are believed to purify the environment, and their by-products, such as cow dung and gaumutra, have traditional medicinal benefits. Additionally, Desi cow ghee is valued for its use in religious rituals and has notable medicinal properties.",
    },

    {
      question: "Why should ShriNarayan Vrihad Gaushala protect cows?",
      answer:
        "Cows are considered divine creatures because they are unique in that they inhale and exhale oxygen, contributing to environmental balance. Their dung and gaumutra help purify the soil and maintain ecological harmony.Additionally, the products derived from cows—milk, curd, ghee, gomutra, and gomay—combine to form Panchagavya, a revered Ayurvedic medicine. This traditional remedy, made from various cow products, is known for its effectiveness in treating numerous medical conditions and chronic ailments. Panchagavya offers a cost-effective alternative to modern medicine, utilizing readily available cow-based products to address health issues.",
    },

    {
      question: "Is milk sold in ShriNarayan Vrihad Gaushala?",
      answer:
        "No, milk is not sold in ShriNarayan Vrihad Gaushala. Since the cows are protected by the Gaushala as per the principles of saints and scriptures, selling milk or milk products is not allowed. The primary objective of the Gaushala is to protect and serve indigenous (desi) cows. Instead of selling milk, it is distributed for free to saints, tapaswis, gausewaks, ashrams, the needy, and hospitals.",
    },

    {
      question: "Why does ShriNarayan Vrihad Gaushala organize Yagya?",
      answer:
        "ShriNarayan Vrihad Gaushala organizes Yagya because a pure environment and the use of pure desi cow ghee are essential for performing Yagya, which is increasingly rare to find today. The Gaushala provides an ideal setting for such rituals. Additionally, products like milk, ghee, cow urine, and cow dung are used in Yagya to enhance its effectiveness and to honor and please the deities.",
    },

    {
      question:
        "Is any kind of charge taken for sheltering the Gauvansh in ShriNarayan Vrihad Gaushala?",
      answer:
        "No charge is taken for sheltering Gauvansh at ShriNarayan Vrihad Gaushala. However, donations are welcome and appreciated to support the Gaushala’s activities and care for the cows.",
    },

    {
      question:
        "Is ShriNarayan Vrihad Gaushala recognized by the Government of India?",
      answer:
        "Yes, ShriNarayan Vrihad Gaushala is recognized and registered with the State Governments of  Uttar Pradesh, as well as with the Government of India. The Gaushala has also received various awards and accolades for its dedicated work ",
    },

    // Add more FAQs as needed
  ];

  // Toggle function to show/hide the answer
  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <SubHeader/>
      <div className="faq-container p-4">
      <div className="flex items-center justify-center m-8 p-8 space-x-4">
      
      <img className="h-8 w-auto" src={icon1} alt="icon1" />
      <p className="text-2xl md:text-3xl  font-bold text-[#F17951] ">Frequently Asked Questions</p>
      <img className="h-8 w-auto" src={icon2} alt="icon2" />
    </div>
        <div className="faq-list shadow-black shadow-lg bg-[#ffd2b3] py-4 px-12 rounded-md mx-24">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="faq-item mb-4 p-4 border border-gray-200 bg-white rounded-lg"
            >
              <button
                className={`faq-question text-lg font-semibold w-full text-left flex items-center justify-between ${openIndex === index ? 'bg-[#F17951] text-white px-4 h-12  rounded-lg' : 'bg-white'}`}
                onClick={() => toggleAnswer(index)}
              >
                {faq.question}
                <span
                  className={`transform transition-transform ${
                    openIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  {/* Chevron icon, rotate based on openIndex */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>
              {openIndex === index && (
                <div className="faq-answer mt-2 text-gray-600 px-4">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
