import React, { useState } from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png"; 
import SubHeader from "../components/SubHeader";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  // const [errors, setErrors] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate phone number
    if (formData.phone.length !== 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }

    // If all validation passes, you can process the form data
    alert("Form submitted successfully!");
  };

  return (
    <>
    <SubHeader/>
      <div className="flex items-center justify-center m-8 p-8 space-x-4">
      
        <img className="h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-2xl font-bold text-[#F17951] ">ShriNarayan Vrihad Gaushala</p>
        <img className="h-8 w-auto" src={icon2} alt="icon2" />
      </div>

      <div className="flex flex-col md:flex-row md:justify-between m-4 gap-4  mx-8 p-8 bg-[#ffd2b3] rounded-md shadow-md shadow-black">
        <div className="bg-white rounded-md flex flex-col justify-center items-center px-4 py-8 w-full md:w-1/2 shadow-2xl shadow-black">
          <form className="w-full max-w-md" onSubmit={handleSubmit}>
            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="bg-gray-50  border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                placeholder="name@flowbite.com"
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Enter your number
              </label>
              <input
                type="text"
                id="phone"
                value={formData.phone}
                onChange={(e) => {
                  // Allow only digits and limit to 10 digits
                  const newValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  setFormData({ ...formData, phone: newValue });
                }}
                className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter your number"
                required
                maxLength="10"
                pattern="\d*"
                title="Please enter a valid 10-digit phone number"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Message
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                className="bg-gray-50 border-2 border-[#F17951] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Your message"
                required
              />
            </div>

            <div className="items-center">
              <button
                type="submit"
                className="text-white bg-[#F17951] items-center hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="shadow-2xl rounded-md w-full md:w-1/2">
          <iframe
            title="address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114591.52890110594!2d82.58136936413896!3d26.164608922962778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39905ef5bb39fcab%3A0xa940b47c0bd8d309!2z4KSX4KS_4KSw4KWN4KS14KS-4KS5LCDgpIngpKTgpY3gpKTgpLAg4KSq4KWN4KSw4KSm4KWH4KS2!5e0!3m2!1shi!2sin!4v1726555246138!5m2!1shi!2sin"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between mx-8 p-8 mt-12 mb-8 gap-4">
        <div className="border-2 border-b-4 border-red-400 rounded text-center p-8 shadow-lg shadow-black bg-white flex-1">
          <h1 className="text-2xl font-bold text-center my-2">
            Shrinarayan Vrihad Gaushala
          </h1>
          <p className="text-center">
            Village: Gairwah, Post: Arshiya, Block: Suithakala
          </p>
          <p className="text-center">Dist: Jaunpur, Uttar Pradesh</p>
          <p className="text-center">+91 8595952508</p>
          <p className="text-center">vgf@vallabhiindia.com</p>
        </div>

        <div className="border-2 border-b-4 border-red-400 rounded-md text-center p-8 shadow-lg shadow-black bg-white flex-1">
          <h1 className="text-2xl font-bold text-center my-2">
            Shrinarayan Vrihad Gaushala
          </h1>
          <p className="text-center">
            Village: Mainuddinpur, Post: Mitawan, Block: Tezi Bazar
          </p>
          <p className="text-center">Dist: Jaunpur, Uttar Pradesh</p>
          <p className="text-center">+91 8595952508</p>
          <p className="text-center">vgf@vallabhiindia.com</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
