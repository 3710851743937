import React, { useState } from "react";
import { Link, Link as Link2 } from "react-router-dom";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { MdTravelExplore } from "react-icons/md";
import { GrAnnounce } from "react-icons/gr";
import { FaBlog } from "react-icons/fa";

import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { AiOutlineProduct } from "react-icons/ai";
import { FaGlobe } from "react-icons/fa";

import {
  IoMdClose,
  IoMdHome,
  IoMdImages,
  IoMdContact,
  IoMdHelp,
} from "react-icons/io";
import {
  FaHandsHelping,
  FaDonate,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaUser,
  FaBriefcase,
  FaQuestionCircle,
  FaRegComment,
  FaHeartbeat,
  // FaHammer,
  // FaCoins,
  FaCarrot,
} from "react-icons/fa";
import { MdOutlineRateReview } from "react-icons/md";
import { GiCow, GiMiracleMedecine } from "react-icons/gi";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import i18n from '../i18n'; // Import i18n
// import i18n from '../i18n'; // Import i18n

const HeaderLinks = ({ isVisible, setIsMenuVisible }) => {

  const { t } = useTranslation(); // Initialize translation


  const minimize = () => {
    setIsMenuVisible(false);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownVisible((prev) => !prev);
  };


  const handleLanguageSelect = (language) => {
    console.log(`Language selected: ${language}`);

    // Change the language using i18next
    i18n.changeLanguage(language.toLowerCase());

    setIsLanguageDropdownVisible(false); // Close dropdown after selection
  };


  // States for managing dropdowns individually
  const [ourActivitiesOpen, setOurActivitiesOpen] = useState(false);
  const [serviceToMotherCowOpen, setServiceToMotherCowOpen] = useState(false);
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);


  // Handle dropdown open/close and style toggling
  const toggleOurActivitiesDropdown = () => {
    setOurActivitiesOpen(!ourActivitiesOpen);
  };

  const toggleServiceToMotherCowDropdown = () => {
    setServiceToMotherCowOpen(!serviceToMotherCowOpen);
  };

  return (
    <div
      className={`header-links fixed top-0 left-0 w-full sm:w-2/3 md:w-1/2 lg:w-1/4 h-full bg-white transition-transform duration-300 z-50 ${isVisible ? "translate-x-0" : "-translate-x-full "
        }`}
    >
      <div className="flex justify-between items-center h-28 w-full bg-[#F17951] px-4 md:px-6 lg:px-2">
        <h1 className="text-white font-bold text-lg md:text-xl lg:text-sm">
          {t("title")}
        </h1>


        <div
          onClick={minimize}
          className="cursor-pointer text-white text-xl md:text-2xl"
        >
          <IoMdClose />
        </div>
      </div>

      <div className="flex flex-col space-y-4 px-4 py-4 overflow-y-auto h-[calc(100vh-7rem)]">
        <div>
          <Link2
            onClick={minimize}
            to="/"
            className="flex items-center space-x-2"
          >
            <IoMdHome className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("home")}</h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/aboutDetail"
            className="flex items-center space-x-2"
          >
            <FaUser className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("about_us")}</h1>
          </Link2>
        </div>

        <div>
          <Link2
            onClick={minimize}
            to="/ourworks"
            className="flex items-center space-x-2"
          >
            <FaBriefcase className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("our_work")}</h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/ourproducts"
            className="flex items-center space-x-2"
          >
            <AiOutlineProduct className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("our_products")}</h1>
          </Link2>
        </div>


        <div>
          <Link2
            onClick={minimize}
            to="/testimonial"
            className="flex items-center space-x-2"
          >
            <MdOutlineRateReview className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("testimonial")}</h1>
          </Link2>
        </div>

        {/* Our Activities Dropdown */}
        <div>
          <div
            className={`flex justify-between items-center cursor-pointer ${ourActivitiesOpen ? "bg-[#F17951] rounded-lg text-white" : ""
              }`}
            onClick={toggleOurActivitiesDropdown}
          >
            <div className="flex-1">
              <h1
                className={`flex items-center space-x-2 ${ourActivitiesOpen
                    ? "text-white font-bold"
                    : "text-[#4D1D00] font-bold"
                  }`}
              >
                <FaHandsHelping className="text-[#F17951]" />
                <span>{t("our_activities")}</span>
              </h1>
            </div>
            <div>
              {ourActivitiesOpen ? (
                <RiArrowDropUpLine
                  className={`text-lg h-8 w-8 flex items-center justify-center ${ourActivitiesOpen ? "text-white" : "text-[#F17951]"
                    }`}
                />
              ) : (
                <RiArrowDropDownLine className="text-lg h-8 w-8 flex items-center justify-center text-[#F17951]" />
              )}
            </div>
          </div>
          {ourActivitiesOpen && (
            <div className="pl-4">
              <ul>
                <li>
                  <Link2
                    to="/gallery"
                    className="flex items-center space-x-2"
                    onClick={minimize}
                  >
                    <IoMdImages className="text-red-900" />
                    <h1 className="text-red-900 font-semibold">{t("gallery")}</h1>
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="/benefits"
                    className="flex items-center space-x-2"
                    onClick={minimize}
                  >
                    <IoMdHelp className="text-red-900" />
                    <h1 className="text-red-900 font-semibold">
                      {t("benefits_of_cow")}
                    </h1>
                  </Link2>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Service To Mother Cow Dropdown */}
        <div>
          <div
            className={`flex justify-between items-center cursor-pointer ${serviceToMotherCowOpen ? "bg-[#F17951] rounded-lg text-white" : ""
              }`}
            onClick={toggleServiceToMotherCowDropdown}
          >
            <div className="flex-1">
              <h1
                className={`flex items-center space-x-2 ${serviceToMotherCowOpen
                    ? "text-white font-bold"
                    : "text-[#4D1D00] font-bold"
                  }`}
              >
                <GiCow className="text-[#F17951]" />
                <span>{t("service_to_mother_cow")}</span>
              </h1>
            </div>
            <div>
              {serviceToMotherCowOpen ? (
                <RiArrowDropUpLine
                  className={`text-lg h-8 w-8 flex items-center justify-center ${serviceToMotherCowOpen ? "text-white" : "text-[#F17951]"
                    }`}
                />
              ) : (
                <RiArrowDropDownLine className="text-lg h-8 w-8 flex items-center justify-center text-[#F17951]" />
              )}
            </div>
          </div>
          {serviceToMotherCowOpen && (
            <div className="pl-4">
              <ul>
                <li>
                  <Link2
                    to="/food"
                    className="flex items-center space-x-2"
                    onClick={minimize}
                  >
                    <FaCarrot className="text-red-900" />
                    <h1 className="text-red-900 font-semibold">{t("for_food")}</h1>
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="/monthlycare"
                    className="flex items-center space-x-2"
                    onClick={minimize}
                  >
                    <FaHeartbeat className="text-red-900" />
                    <h1 className="text-red-900 font-semibold">
                      {t("monthly_care")}
                    </h1>
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="/medicine"
                    className="flex items-center space-x-2"
                    onClick={minimize}
                  >
                    <GiMiracleMedecine className="text-red-900" />
                    <h1 className="text-red-900 font-semibold">{t("medicines")}</h1>
                  </Link2>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div>
          <Link2
            onClick={minimize}
            to="/contact"
            className="flex items-center space-x-2"
          >
            <IoMdContact className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("contact_us")}</h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/donate"
            className="flex items-center space-x-2"
          >
            <FaDonate className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("donate_now")}</h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/faq"
            className="flex items-center space-x-2"
          >
            <FaQuestionCircle className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("faq")}</h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/feedback"
            className="flex items-center space-x-2"
          >
            <FaRegComment className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("feedback")}</h1>
          </Link2>
        </div>

        <div>
          <Link2
            onClick={minimize}
            to="/gausevak"
            className="flex items-center space-x-2"
          >
            <LiaHandsHelpingSolid className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">{t("BecomeGausevak")}</h1>
          </Link2>
        </div>

        <div>
          <Link2
            onClick={minimize}
            to="/visitgaushala"
            className="flex items-center space-x-2"
          >
            <MdTravelExplore className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold"> {t("VisitGaushala")} </h1>
          </Link2>
        </div>
        <div>
          <Link2
            onClick={minimize}
            to="/announcements"
            className="flex items-center space-x-2"
          >
            <GrAnnounce className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">Announcements </h1>
          </Link2>
        </div>

        <div>
          <Link2
            onClick={minimize}
            to="/blogsection"
            className="flex items-center space-x-2"
          >
            <FaBlog className="text-[#F17951]" />
            <h1 className="text-[#4D1D00] font-bold">Blog</h1>
          </Link2>
        </div>
        {/* <div className="flex items-center gap-4">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={30} color="#4267B2" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={30} color="#1DA1F2" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={30} color="#E1306C" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={30} color="#0077B5" />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderLinks;
