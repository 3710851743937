import React, { useContext } from "react";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { CartContext } from "../components/Context";
import fertilizer from "../assests/ourProducts/fertilizer.jpg"
import uple from "../assests/ourProducts/uple.jpg"
import SubHeader from "../components/SubHeader";

function OurProducts() {
    const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  const items = [
    {
      id: 16,
      type:"buy_product",
      title: "Cow Fertilizers",
      image: fertilizer,
      price: 500,
      unit: "per 5 Kg",
      text: "Cow dung manure enriches soil with essential nutrients, improves soil structure, enhances moisture retention, promotes beneficial microbial activity, and is an eco-friendly, natural alternative to chemical fertilizers, supporting sustainable agriculture.",
    },
    {
      id: 17,
      type:"buy_product",
      title: "Natural Desi Cow Dung Cake",
      image: uple,
      price: 100,
      unit: "Pack of 10",
      text: "Natural desi cow dung cakes provide organic nutrients, enhance soil fertility, improve water retention, support beneficial microbes, and are eco-friendly, serving as an effective, traditional fertilizer for sustainable farming practices.",
    },
  ];
    return ( 
        <>
    <SubHeader/>
    <div className="container mx-auto p-6 bg-[#ffd2b3] rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center mb-6 text-[#F17951]">
      Our Products
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map((item) => (
          <div
            key={item.id}
            className="border rounded-lg p-4 text-center shadow-md shadow-black flex flex-col justify-between"
          >
            <div>
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-60 object-cover mb-2 rounded"
              />
            </div>

            <div>
              <h3 className="font-semibold text-lg">{item.title}</h3>
              <p >{item.text}</p>
            </div>

            <div className="flex justify-between items-center">

              <div className="flex justify-between my-4">
                <div>
                  <h1 className="text-lg font-bold">{item.price}/- </h1>
                  <h3>{item.unit}</h3>
                </div>
              </div>

              <div className="bg-[#F17951] flex justify-between items-center rounded-lg">
                <button
                  onClick={() => removeFromCart(item.id)}
                  className="px-4 py-3"
                >
                  <FiMinus className="border-2 border-black" />
                </button>
                <p>
                  {cartItems.find((cartItem) => cartItem.id === item.id)
                    ?.quantity || 0}
                </p>
                <button onClick={() => addToCart(item)} className="px-4">
                  <IoMdAdd className="border-2 border-black" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
     );
}

export default OurProducts;