import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderLinks from "./HeaderLinks";
import { IoMdMenu } from "react-icons/io";
import { FcCalendar } from "react-icons/fc";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { FaBlog } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { CartContext } from "../components/Context";
import { GrAnnounce } from "react-icons/gr";
import Cart from "./Cart";
import { useTranslation } from "react-i18next";
import { MhahPanchang } from 'mhah-panchang';

const Header = () => {
  const { t } = useTranslation();
  const { cartCount } = useContext(CartContext);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [closeCart, setCloseCart] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);
  const toggleCart = (event) => {
    event.stopPropagation();
    setCloseCart((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".header-links") &&
        !event.target.closest(".header button") &&
        !event.target.closest(".language-dropdown")
      ) {
        setIsMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    if (window.google && window.google.translate) {
      const element = document.getElementById("google_translate_element");
      if (element) {
        window.google.translate.TranslateElement.getInstance().setEnabled(true);
        window.google.translate.TranslateElement.getInstance().setLanguage(lang);
      }
    }
  };

  const [panchangData, setPanchangData] = useState(null);
  const [latitude, setLatitude] = useState(12.972);
  const [longitude, setLongitude] = useState(77.594);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const obj = new MhahPanchang();
    const calculatedData = obj.calculate(date);
    const calendarData = obj.calendar(date, latitude, longitude);
    setPanchangData({ calculatedData, calendarData });
  }, [date, latitude, longitude]);

  return (
    <header className="flex justify-between items-center bg-[#F17951] p-4 h-24 sm:h-28 flex-wrap relative">
      <div className="flex justify-between items-center gap-4"> {/* Menu Button */}
        <div><button
          onClick={toggleMenu}
          type="button"
          className="focus:outline-none text-white bg-red-900 hover:bg-red-700 rounded-full p-2"
        >
          <IoMdMenu className="h-6 w-6" />
        </button>
        </div>
        <div className="flex-grow text-center">
          <Link
            to="/"
            className="text-white font-semibold lg:font-bold lg:text-xl"
          >
            {t("title")}
          </Link>
        </div>

      </div>

      {/* Panchang Section */}
      <div className="flex hidden md:flex sm:flex justify-around items-center text-center py-2  px-2 rounded-lg text-white gap-4 lg:w-60 sm: lh:w-full">
        <div className="overflow-hidden items-center whitespace-nowrap relative">
          <div className="marquee-content inline-block animate-marquee">
            <div className="flex items-center justify-center ">
              <div className="inline-block text-center px-4">
                <span className="font-semibold">Tithi:</span> {panchangData?.calculatedData?.Tithi?.name_en_IN || 'N/A'}
              </div>
              <div className="inline-block text-center px-4">
                <span className="font-semibold">Paksha:</span> {panchangData?.calculatedData?.Paksha?.name_en_IN || 'N/A'}
              </div>
              <div className="inline-block text-center px-4">
                <span className="font-semibold">Nakshatra:</span> {panchangData?.calculatedData?.Nakshatra?.name_en_IN || 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center gap-8 ms-4 sm:flex-nowrap flex-wrap w-full sm:w-auto">
        {/* Adopt Cow and Join Us - Hidden on small screens */}
        <div className="hidden sm:block">
          <Link to="/monthlycare" className="text-white font-semibold">
            Adopt Cow
          </Link>
        </div>

        <div className="hidden sm:block">
          <Link
            to="#"
            className="text-white font-semibold hover:text-red-200"
            onClick={toggleDropdown}
          >
            {t("join_us")}
          </Link>
        </div>

        {isDropdownOpen && (
          <div className="absolute bg-[#ffd2b3] border border-gray-300 z-10 mt-40 rounded shadow-md w-64">
            <ul className="p-2 ">
              <li className="border border-black mb-2">
                <Link
                  to="/gausevak"
                  onClick={toggleDropdown}
                  className="block  px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  {t("BecomeGausevak")}
                </Link>
              </li>
              <li className="border border-black">
                <Link
                  to="/visitgaushala"
                  onClick={toggleDropdown}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  {t("VisitGaushala")}
                </Link>
              </li>
            </ul>
          </div>
        )}

        {/* Calendar Icon */}


        <div className="relative hidden sm:block">
          <Link to="/blogsection" className="text-white font-semibold">
            Blog
          </Link>
        </div>

        <div className="relative">
          <Link
            to="/hindicalendar"
            className="text-white font-semibold "
          >
            <IoCalendarNumberOutline className="text-white h-8 w-8" />
          </Link>
        </div>


        <div className="relative hidden sm:block">
          <Link
            to="/announcements"
            className="text-white font-semibold "
          >
            <GrAnnounce className="text-white h-8 w-8 ms-4" />
          </Link>
        </div>

        {/* Google Translate (Hidden on small screens) */}
        <div className="hidden sm:block mt-4">
          <div id="google_translate_element"></div>
        </div>

        {/* Cart Icon */}
        <div className="relative mt-2">
          <button onClick={toggleCart} className="focus:outline-none">
            <IoCartOutline className="text-white h-8 w-8" />
            {cartCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-600 text-white text-xs font-bold rounded-full px-1">
                {cartCount}
              </span>
            )}
          </button>
        </div>

        {/* Donate Button - Hidden on small screens */}
        <div className="  justify-end ms-4">
          <Link to="/donate" className="inline-block">
            <button
              type="button"
              className="text-white bg-red-900 hover:bg-red-700 rounded-lg text-sm px-4 py-2 transition duration-200"
            >
              {t("donate_now")}
            </button>
          </Link>
        </div>

      </div>



      <HeaderLinks
        isVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
      <Cart isVisible={closeCart} setIsMenuVisible={setCloseCart} />
    </header>
  );
};

export default Header;
