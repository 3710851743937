import axios from 'axios';
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import SubHeader from "../components/SubHeader";
import { CartContext } from "../components/Context";
import { Bars } from 'react-loader-spinner'; 

function CartForm() {
    const [titles, setTitles] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0); 
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [productType, setProductType] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); 

    const validateForm = () => {
        const newErrors = {};
        if (!formData.fullName) newErrors.fullName = 'Full Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.fullAddress) newErrors.fullAddress = 'Address is required';
        return newErrors;
    };

    const { cartItems, clearCart } = useContext(CartContext);

    useEffect(() => {
        if (cartItems.length > 0) {
            const itemTitles = cartItems.map(item => `${item.title} X ${item.quantity}`);
            const itemType = cartItems[0].type;
            const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
            const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);

            setTitles(itemTitles); // Set titles array
            setTotalPrice(totalPrice); // Set total price
            setTotalQuantity(totalQuantity); // Set total quantity
            setProductType(itemType);
        }
    }, [cartItems]);

    // Form data with the total price
    const [formData, setFormData] = useState({
        amount: totalPrice,
        email: "",
        phone: "",
        fullName: "",
        fullAddress: "",
        cartItems: titles, // Array of titles
        itemPrice: totalPrice // Total price of all items
    });

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            amount: totalPrice,
            cartItems: titles, // Set updated titles
            itemQuantity: totalQuantity, // Set updated quantity
            itemPrice: totalPrice // Set updated price
        }));
    }, [titles, totalPrice, totalQuantity]);

    // Handle state change
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handlePayment = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const data = {
            name: formData.fullName,
            address: formData.fullAddress,
            mobileNumber: formData.phone,
            amount: formData.amount,
            email: formData.email,
            product: titles,
            type: productType
        };

        setLoading(true); // Start loading

        try {
            // Simulating payment processing with a delay
            await new Promise((resolve) => setTimeout(resolve, 5000)); // Simulate a delay

            // Now perform the actual API call
            const response = await axios.post('https://gaushala-backend-qnc9.onrender.com/create-order', data);
            console.log(response.data);
            window.location.href = response.data.url; // Redirect after successful payment
            
        } catch (error) {
            console.log("Error in payment", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            <div className="container mx-auto px-8 py-8">
                <SubHeader />
                <div className="flex mb-4">
                    <div className="w-1/2 shadow-sm shadow-black rounded-lg">
                        <div className="p-4 flex-1 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                            {cartItems.length === 0 ? (
                                <p>Your cart is empty.</p>
                            ) : (
                                cartItems.map(({ id, title, price, quantity, image }) => (
                                    <div key={id} className="flex items-center justify-between mb-4 border-b pb-2">
                                        <img src={image} alt={title} className="w-16 h-16 object-cover" />
                                        <div className="flex-1 ml-4">
                                            <h2 className="font-semibold">{title}</h2>
                                            <p>Price: ₹{price * quantity}</p>
                                            <p>Quantity: {quantity}</p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="p-2 mx-4  bg-white sticky bottom-0">
                            <div className="flex justify-between text-lg font-semibold">
                                <span>Total:</span>
                                <span>₹{totalPrice.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 ">
                        {/* Block 1: Choose Currency & Amount */}
                        <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 mx-auto max-w-custom">
                            <h2 className="text-2xl font-bold mb-4 text-[#F17951]">Choose Currency & Amount</h2>

                            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
                                {/* Full Name */}
                                <div className="w-full">
                                    <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900">
                                        Full Name
                                    </label>
                                    <input
                                        id="fullName"
                                        name="fullName"
                                        type="text"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        placeholder="Enter your full name"
                                        className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.fullName ? 'border-red-500' : 'border-[#F17951]'}`}
                                        required
                                    />
                                    {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
                                {/* Full Address */}
                                <div className="w-full">
                                    <label htmlFor="fullAddress" className="block mb-2 text-sm font-medium text-gray-900">
                                        Address
                                    </label>
                                    <input
                                        id="fullAddress"
                                        name="fullAddress"
                                        type="text"
                                        value={formData.fullAddress}
                                        onChange={handleChange}
                                        placeholder="Enter your full Address"
                                        className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.fullAddress ? 'border-red-500' : 'border-[#F17951]'}`}
                                        required
                                    />
                                    {errors.fullAddress && <p className="text-red-500 text-sm mt-1">{errors.fullAddress}</p>}
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
                                {/* Email Address */}
                                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                                        Email Address
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.email ? 'border-red-500' : 'border-[#F17951]'}`}
                                        required
                                    />
                                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                </div>

                                {/* Phone Number */}
                                <div className="w-full md:w-1/2">
                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                                        Phone Number
                                    </label>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="Enter your phone number"
                                        className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.phone ? 'border-red-500' : 'border-[#F17951]'}`}
                                        required
                                    />
                                    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Proceed Button */}
                        <div className="flex justify-center items-center px-8 sm:px-6 md:px-8 lg:px-64">
                            {loading ? (
                                <Bars
                                    height="40"
                                    width="40"
                                    color="#F17951" // or any color you prefer
                                    ariaLabel="loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            ) : (
                                <button
                                    type="button"
                                    onClick={handlePayment}
                                    className="text-white bg-[#F17951] focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm sm:text-base px-4 sm:px-5 py-2.5 sm:py-3 me-2 mb-2 dark:bg-red-600 hover:bg-[#f38b68] focus:outline-none dark:focus:ring-red-800 w-full max-w-xs"
                                >
                                    Proceed
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CartForm;
