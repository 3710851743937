import React, { useState } from "react";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import SubHeader from "../components/SubHeader";
import axios from 'axios';
import { Bars } from 'react-loader-spinner'; // or another spinner of your choice

function Donate() {
  const createOrderURL = "https://gaushala-backend-qnc9.onrender.com/create-order";
  // State to manage form fields
  const [formData, setFormData] = useState({
    currency: "",
    amount: "",
    email: "",
    phone: "",
    fullName: "",
    panNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  // Handle state change
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };
  // Validation logic
  const validateForm = () => {
    const newErrors = {};

    if (!formData.amount || isNaN(formData.amount)) {
      newErrors.amount = "Amount is required and must be a number.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email format is invalid.";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.fullName) {
      newErrors.fullName = "Full name is required.";
    }
    if (!formData.panNumber || formData.panNumber.length !== 10) {
      newErrors.panNumber = "PAN number is required and must be 10 characters.";
    }
    return newErrors;
  };

  const handlePayment = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true); // Start loader

    const data = {
      name: formData.fullName,
      mobileNumber: formData.phone,
      amount: formData.amount,
      email: formData.email,
      panNumber: formData.panNumber,
      type: "donate"
    };

    try {
      const response = await axios.post(createOrderURL, data);
      // original href
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      }
      else {
        window.location.href = `/payment-success?name=${encodeURIComponent(formData.fullName)}&amount=${encodeURIComponent(formData.amount)}`;
      }

    } catch (error) {
      console.log("Error in payment", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="container mx-auto px-8 py-8">
      <SubHeader />
      <div className="flex items-center justify-center lg:m-8 sm:m-2 sm:p-2 sm:text-sm lg:p-8 space-x-4 text-center">
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon1} alt="icon1" />
        <p className="text-sm sm:text-lg lg:text-2xl font-bold text-[#F17951]">
          Shrinarayan Vrihad Gaushala Donation Form
        </p>
        <img className="h-4 sm:h-4 lg:h-8 w-auto" src={icon2} alt="icon2" />
      </div>


      {/* Block 1: Choose Currency & Amount */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 mt-4 mx-auto max-w-custom">
        <h2 className="text-2xl font-bold mb-4 text-[#F17951]">Choose Currency & Amount</h2>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-full">
            <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
              Enter Amount
            </label>
            <input
              id="amount"
              name="amount"
              type="text"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter Amount"
              className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.amount ? 'border-red-500' : 'border-[#F17951]'}`}
            />
            {errors.amount && <p className="text-red-500 text-sm">{errors.amount}</p>}
          </div>
        </div>
      </div>

      {/* Block 2: Personal Details */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 mx-auto max-w-custom">
        <h2 className="text-2xl font-bold mb-4 text-[#F17951]">Personal Details</h2>
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              Email Address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.email ? 'border-red-500' : 'border-[#F17951]'}`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
              Phone Number
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.phone ? 'border-red-500' : 'border-[#F17951]'}`}
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900">
              Full Name
            </label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.fullName ? 'border-red-500' : 'border-[#F17951]'}`}
            />
            {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="panNumber" className="block mb-2 text-sm font-medium text-gray-900">
              PAN Number
            </label>
            <input
              id="panNumber"
              name="panNumber"
              type="text"
              value={formData.panNumber}
              onChange={handleChange}
              placeholder="Enter your PAN number"
              className={`bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#F17951] focus:border-[#F17951] block w-full p-2.5 ${errors.panNumber ? 'border-red-500' : 'border-[#F17951]'}`}
            />
            {errors.panNumber && <p className="text-red-500 text-sm">{errors.panNumber}</p>}
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center px-8 sm:px-6 md:px-8 lg:px-64">
        {loading ? (
          <Bars
            height="40"
            width="40"
            color="#F17951" // or any color you prefer
            ariaLabel="loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <button
            type="button"
            onClick={handlePayment}
            className="text-white bg-[#F17951] focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm sm:text-base px-4 sm:px-5 py-2.5 sm:py-3 me-2 mb-2 dark:bg-red-600 hover:bg-[#f38b68] focus:outline-none dark:focus:ring-red-800 w-full max-w-xs"
          >
            Proceed
          </button>
        )}
      </div>
    </div>
  );
}

export default Donate;