import React from "react";
import Home from "../components/Home";
import About from "../pages/About";
import Gallery from "./Gallery";
import Testimonial from "./OurWorks";
import OurWork from "./Testimonials";
import WebsiteLiveVisitors from "./WebsiteLiveVisitors";
const MainPage = () => {
  return (
    <div>
      <Home />
      <WebsiteLiveVisitors />
      <About />
      <Testimonial showSubHeader={false} />
      <Gallery showSubHeader={false} />
      {/* <ImageGallery/> */}
      {/* <VideoGallery/> */}
      <OurWork showSubHeader={false} />
      {/* <WebsiteVisitors/> */}
      {/* <WebsiteLiveVisitors/> */}
    </div>
  );
};

export default MainPage;
